import type * as Sentry from '@sentry/vue'
import type { App } from 'vue'
import type { GtmSupport } from '@gtm-support/vue-gtm'
import { version } from '../../package.json'

import taptic from './directives/taptic'
import bodyScrollLock from './directives/body_scroll_lock'
import draggable from './directives/draggable'

// Globals
import { createSegment, provideSegment } from '@/composables/useSegment'

import isMobile from './globals/is_mobile'
import snackbar from './globals/snackbar'
import axiosHelper from './globals/axios'
import { provideAxios } from '@/composables/useAxios.js'
import { filters } from './globals/filters'
import api from './globals/api'

type PluginOptions = {
  i18n: any
  sentry: typeof Sentry | undefined
  gtm: GtmSupport | undefined
}

export function plugins(options: PluginOptions) {
  return {
    async install(app: App) {
      const axios = app.config.globalProperties.$axios
      const router = app.config.globalProperties.$router
      const store = app.config.globalProperties.$store

      app.directive('taptic', taptic)
      app.directive('body-scroll-lock', bodyScrollLock)
      app.directive('draggable', draggable)

      const addGlobal = (name, instance) => {
        app.config.globalProperties[name] = instance
      }

      // Add http client
      provideAxios(app, axios)

      axios.$get = async (request, options) => {
        const response = await axios.get(request, options)
        return response.data
      }

      filters.forEach(([key, value]) => {
        app.config.globalProperties[key] = value
        app.provide(key, value)
      })

      addGlobal('$axios', axios)

      // Globals
      const plugins = [
        snackbar,
        isMobile,
        api,
      ]

      plugins.map((f) => {
        f({ store, $axios: axios }, (name, instance) =>
          addGlobal(`$${name}`, instance)
        )
      })

      axiosHelper({
        store,
        app: {
          $axios: axios,
          $sentry: options.sentry,
          i18n: options.i18n,
        },
        appVersion: version,
      })

      // Vuex properties
      store.$axios = axios
      store.$api = app.config.globalProperties.$api
      store.$snackbar = app.config.globalProperties.$snackbar
      store.$i18n = options.i18n
      store.$gtm = options.gtm
      store.$sentry = options.sentry

      if (!import.meta.env.SSR) {
        // Fixes for Facebook native app browser
        window._AutofillCallbackHandler =
          window._AutofillCallbackHandler || function () {}
        window.instantSearchSDKJSBridgeClearHighlight =
          window.instantSearchSDKJSBridgeClearHighlight || function () {}
      }

      const segment = await createSegment({ store, router, sentry: options.sentry })

      addGlobal('$segment', segment)
      provideSegment(app, segment)

      store.$segment = app.config.globalProperties.$segment
    },
  }
}
