import { v4 as createUuid } from 'uuid'
import { TYPES } from '@/plugins/globals/snackbar'

const ADD_SNACK = 'ADD_SNACK'
const REMOVE_SNACK = 'REMOVE_SNACK'
const UPDATE_SNACK = 'UPDATE_SNACK'

const sanityCheck = (options) => {
  // Check if the new type is known
  if (
    options.type &&
    !Object.values(TYPES).includes(options.type)
  ) {
    throw new Error(
      `Snack type "${
        options.type
      }" is not a valid type, please use one of the following: [${TYPES.join(
        ', '
      )}]`
    )
  }

  // Check if actions is an array of functions
  if (
    options.actions &&
    (!Array.isArray(options.actions) ||
      options.actions.some((item) => typeof item !== 'function'))
  ) {
    throw new Error('Snack actions should be an array of functions.')
  }
}

const createDefaultState = () => ({
  snacks: [],
})

export const state = () => ({
  ...createDefaultState(),
})

export const getters = {
  snacks: (state) => state.snacks,
}

export const actions = {
  add: ({ commit }, snack) => {
    const uuid = createUuid()

    commit(ADD_SNACK, {
      ...snack,
      uuid,
    })

    return uuid
  },

  remove: ({ commit }, snack) => {
    commit(REMOVE_SNACK, snack)
  },

  update: ({ commit, getters }, { uuid, ...options }) => {
    const old = getters.snacks.find((snack) => snack.uuid === uuid)

    sanityCheck(options)

    if (old) commit(UPDATE_SNACK, {
      uuid,
      ...options,
    })

    // If there's no old snack, create a new one
    if (!old) commit(ADD_SNACK, { ...options })
  },
}

export const mutations = {
  [ADD_SNACK]: (state, snack) => {
    state.snacks.push(snack)
  },

  [REMOVE_SNACK]: (state, snack) => {
    const index = state.snacks.findIndex(({ uuid }) => uuid === snack.uuid)
    state.snacks.splice(index, 1)
  },

  [UPDATE_SNACK]: (state, options) => {
    const index = state.snacks.findIndex(({ uuid }) => uuid === options.uuid)
    state.snacks[index] = { ...state.snacks[index], ...options }
  },
}
