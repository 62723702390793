import { vueSSR } from 'vite-plugin-vue-ssr'
import axios from 'axios'
import { createGtm, useGtm } from '@gtm-support/vue-gtm'
import { type RouteRecordRaw, createMemoryHistory, createRouter, createWebHistory } from 'vue-router'
import { Capacitor } from '@capacitor/core'
import App from '@/App.vue'
import { createI18n } from '@/i18n'
import { mapRoutesWithLocale, routes as _routes } from '@/routes'
import { createStore, key } from '@/stores'
import { nuxtPolyfills } from '@/nuxt-polyfills'
import { plugins } from '@/plugins'
import { createSentry } from '@/plugins/sentry'
import { determineCountryAndLocaleFromUrl } from '@/internal/determineCountryAndLocaleFromUrl'
import { Device } from '@capacitor/device'
import { useGoogleMaps } from './composables/googleMaps'

export default vueSSR(App, {}, async ({ app, state, request }) => {
  const http = axios.create()
  http.defaults.baseURL = `${import.meta.env.VITE_BACKEND_URL}${import.meta.env.VITE_API_PATH}`
  app.config.globalProperties.$axios = http

  const store = createStore()
  app.use(store, key)

  if (!__CAPACITOR__) {
    if (import.meta.env.SSR) {
      state.value = store.state

      if (request.cookies.token !== undefined) {
        await store.dispatch('user/setToken', JSON.parse(Buffer.from(request.cookies.token, 'base64').toString()))
      }

      if (request.cookies.location !== undefined) {
        // note: this breaks the German eszett for example
        // await store.dispatch('session/storeCurrentLocation', JSON.parse(decodeURIComponent(Buffer.from(request.cookies.location, 'base64').toString())))
        await store.dispatch('session/storeCurrentLocation', JSON.parse(Buffer.from(request.cookies.location, 'base64').toString()))
      }

      if (request.cookies.distribution !== undefined) {
        await store.dispatch('session/storeDistributionType', request.cookies.distribution)
      }
    } else {
      // into the VueX state
      const cart = {
        ...store.state.cart,
        establishment: state.value.cart.establishment,
      }

      const session = {
        ...store.state.session,
        distributionType: state.value.session.distributionType,
        currentLocation: state.value.session.currentLocation,
        locale: state.value.session.locale,
        country: state.value.session.country,
        onLocationIdentifier: state.value.session.onLocationIdentifier,
        // temporaryLocation: state.value.session.temporaryLocation,
      }

      store.replaceState({
        ...state.value,
        // instead replacing the empty SSR initial state
        // the actual state from the localStorage is used
        session,
        user: store.state.user,
        cart,
      })
    }
  }

  if (import.meta.env.SSR) {
    const { country, locale } = determineCountryAndLocaleFromUrl(request.hostname ?? 'localhost', request.originalUrl)

    await store.dispatch('session/storeCurrentLocale', locale)
    await store.dispatch('session/storeCurrentCountry', country)
  }

  const i18n = createI18n()
  app.use(i18n)

  const routes: RouteRecordRaw[] = _routes.reduce<RouteRecordRaw[]>((prev, current) => [ ...prev, ...i18n.global.availableLocales.reduce<RouteRecordRaw[]>((lp, lc) => [ ...lp, ...mapRoutesWithLocale(current, lc, store.state.session.country) ], []) ], [])

  let base = '/'

  const COM_DOMAINS = ['be'] as const

  if (COM_DOMAINS.includes(store.state.session.country)) {
    base = `/${store.state.session.country}/`
  }

  const router = createRouter({
    history: import.meta.env.SSR ? createMemoryHistory(base) : createWebHistory(base),
    routes,
    scrollBehavior: (to, from, savedPosition) => {
      if ((to.name as string)?.startsWith('marketplace')
        && (from.name as string)?.startsWith('establishment')
        && store.state.marketplace.currentPage > 1)
      {
        const { y } = store.state.marketplace.scrollPosition

        return new Promise((resolve) => {
          setTimeout(() => {
            resolve({ left: 0, top: y })
          }, 10)
        })

        // return { left: 0, top: y }
      }

      if (to.name === from.name) return

      if (to.hash) {
        return { el: to.hash, behavior: 'smooth' }
      }

      if (savedPosition) {
        return savedPosition
      } else {
        return { top: 0 }
      }
    },
  })
  app.use(router)

  let locale = store.state.session.locale

  router.beforeEach(to => {
    if (to.meta.requiresAuth && !store.getters['user/loggedIn']) {
      return { name: `login___${locale}` }
    }

    return true
  })

  app.provide('platform', Capacitor.isNativePlatform() ? Capacitor.getPlatform() : 'web')

  app.use(nuxtPolyfills(i18n))

  const sentry = await createSentry({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_BUILD_ENV,
    router,
  })
  app.use(sentry)

  app.use(createGtm({
    id: import.meta.env.VITE_GOOGLE_GTM,
    defer: true,
    compatibility: false,
    enabled: true,
    debug: import.meta.env.DEV,
    loadScript: true,
    trackOnNextTick: false,
  }))

  if (!import.meta.env.SSR) {
    if (window && window.dataLayer) {
      // eslint-disable-next-line no-inner-declarations
      function gtag() {
        // eslint-disable-next-line prefer-rest-params
        window.dataLayer?.push(arguments)
      }

      // https://support.cookiebot.com/hc/en-us/articles/360016047000-Cookiebot-and-Google-Consent-Mode
      gtag('consent', 'default', {
        ad_personalization: 'denied',
        ad_storage: 'denied',
        ad_user_data: 'denied',
        analytics_storage: 'denied',
        functionality_storage: 'denied',
        personalization_storage: 'denied',
        security_storage: 'granted',
        wait_for_update: 500,
      })
      gtag('set', 'ads_data_redaction', true)
      gtag('set', 'url_passthrough', true)
    }
  }

  const gtm = useGtm()
  app.use(plugins({ i18n, sentry: sentry.instance, gtm }))

  if (__CAPACITOR__) {
    const deviceLanguage = await Device.getLanguageCode()

    const { capacitor } = await import('@/capacitor')

    locale = deviceLanguage.value

    capacitor(router, store)
  }

  i18n.global.locale.value = locale

  const { createLoader } = useGoogleMaps()
  createLoader(app)

  return {
    router,
  }
})
